import React from 'react';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck, FiPieChart, FiArrowDownRight, FiX } from "react-icons/fi";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import SlpitOne from "../elements/split/SlpitOne";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import TeamOne from "../elements/team/TeamOne";
import BrandTwo from "../elements/brand/BrandTwo";
import ProgressbarTwo from "../elements/progressbar/ProgressbarTwo";
import Copy from "../elements/copytoclip/Copy"
import BrandOne from "../elements/brand/BrandOne";
import FooterFour from '../common/footer/FooterFour';






const Company = () => {
    return (
        <>
            <SEO title="" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-750">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">


                                    <h3 className="subtitle">Total - 100M Token Supply</h3>
                                    <br/>
                                    <div className="rbt-progress-style-1 no-radius large-size">
                                        <ProgressbarTwo />
                                    </div>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiPieChart /></span> Token Circulation - 44.85M</li>
                                        <li><span className="icon"><FiPieChart /></span> Total Tax - 0%</li>
                                        <li><span className="icon"><FiPieChart /></span> Total Burned - 55.15M</li>
                                    </ul>
                                    <div><a href="https://etherscan.io/token/0x24ccedebf841544c9e6a62af4e8c2fa6e5a46fde" target="_blank" >ETHERSCAN LINK </a> &nbsp; &nbsp;
                                  <Copy/>
                                    </div>
                                    <div className="button-group mt--40">
                                        <a className="btn-default btn-small round btn-icon" target="_blank" href="https://app.uniswap.org/#/swap?outputCurrency=0x24ccedebf841544c9e6a62af4e8c2fa6e5a46fde&chain=mainnet">Buy From Uniswap<i className="icon"><FiArrowRight /></i></a>
                                        <a className="btn-default btn-small btn-border round btn-icon" target="_blank" href="https://www.bluebit.io/en_US/trade/BlueSparrow_USDT">Buy From BlueBit<i className="icon"><FiArrowRight /></i></a>
                                        <a className="btn-default btn-small btn-border-blue round btn-icon" target="_blank" href="https://coinmarketcap.com/exchanges/bluebit/">CMC Bluebit Listing<i className="icon"><FiArrowRight /></i></a>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-6 order-1 order-lg-2">
                                <div className="frame-image">
                                    <img src="./images/logo/bluesparrow-logo.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="service-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row align-items-top">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h4 className="title"></h4>
                                    <p> The BlueSparrow token is the official native token to <u><a href="https://bluebit.io" target="_blank" >BlueBit.io</a></u> which offers full <b> Forex, Commodities, Stocks, Indices, and Margin Trading,</b> including an iOS app and its very own NFT Marketplace.</p>

                                </div>
                            </div>
                            <div className="col-lg-3">
                            <SectionTitle
                                    textAlign = "text-left"
                                    radiusRounded = ""
                                    subtitle = "BlueSparrow Token Key Features"
                                   />
                                     <ul className="list-icon">
                                        <li><span className="icon"><FiArrowDownRight /></span> Native Token To &nbsp; <u><a href="https://bluebit.io" target="_blank" >BlueBit.io</a></u> </li>
                                        <li><span className="icon"><FiArrowDownRight /></span> Native Token To &nbsp; <u><a href="https://bluevinci.io" target="_blank" >BlueVinci.io</a></u> </li>
                                        <li><span className="icon"><FiArrowDownRight /></span> Zero Tax </li>
                                        <li><span className="icon"><FiArrowDownRight /></span> Low Supply </li>

                                    </ul>
                                
                            </div>
                            <div className="col-lg-3">
                            <SectionTitle
                                    textAlign = "text-left"
                                    radiusRounded = ""
                                    subtitle = "BlueBit Exchange Key Features"
                                   
                                    />
                                    <ul className="list-icon">
                                        <li><span className="icon"><FiArrowDownRight /></span> Centralized Exchange</li>
                                        <li><span className="icon"><FiArrowDownRight /></span> Crypto & Financial Market</li>


                                    </ul>
                                    
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />


                                    {/* Start Brand listed in exchange Area  */}
                                    <div className="container">

                                    <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                        </div>
                    {/* End Brand Area  */}


                    <Separator />


                <SlpitOne />

 


                <Separator />



                {/* Start road Elements Area  */}


                <div className="rwt-team-area rn-section-gap">
                    <div className="wrapper plr--65">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Road Map"
                                        title = ""
                                        description = ""
                                    />
                            </div>
                        </div>

                        <div className="service-area rn-section">
                    <div className="container">
                        <div className="row align-items-top">
                            
                        

                            <div className="col-lg-3">
                            
                                        <p><h4>Phase 1</h4></p>
                                    
                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Token Launch</li>
                                        <li><span className="icon"><FiCheck /></span> Contract Audit V1</li>
                                        <li><span className="icon"><FiCheck /></span> Website Launch V1</li>
                                        <li><span className="icon"><FiCheck /></span> Social Media Launch</li>

                                        <li><span className="icon"><FiCheck /></span> 1000+ Holders</li>
                                        <li><span className="icon"><FiCheck /></span> Daily Draw 250+ Holders</li>
                                        <li><span className="icon"><FiCheck /></span> 2000+ Community Members</li>



                                    </ul>
                                    
                            </div>



                            <div className="col-lg-3">
                            <p><h4>Phase 2</h4></p>

                                     <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Weekly Draw 1000+ Holders</li>
                                        <li><span className="icon"><FiCheck /></span> Coingecko Listing</li>
                                        <li><span className="icon"><FiCheck /></span> CoinMarketCap Listing</li>
                                        <li><span className="icon"><FiCheck /></span>5000+ Holders </li>

                                        <li><span className="icon"><FiCheck /></span>  10000+ Community Members</li>
                                        <li><span className="icon"><FiCheck /></span>  Monthly Draw after 30 Days</li>

                                        <li><span className="icon"><FiCheck /></span>  Apple Store App</li>

                                        <li><span className="icon"><FiCheck /></span>  CEX Launch (BlueBit.io)</li>
                                        <li><span className="icon"><FiCheck /></span>  MT5 + Forex Launch</li>



                                    </ul>
                                
                            </div>


                            <div className="col-lg-3">
                            <p><h4>Phase 3</h4></p>

                            <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span>  Listing On Small Exchanges</li>
                                        <li><span className="icon"><FiCheck /></span>  New Partnerships</li>
                                        <li><span className="icon"></span> 15000+ Holders</li>
                                        <li><span className="icon"><FiCheck /></span>Influencers Marketing</li>
                                        <li><span className="icon"><FiCheck /></span>  Website Launch V2</li>


                                        <li><span className="icon"><FiCheck /></span>  Whitepaper V1</li>
                                        <li><span className="icon"><FiCheck /></span>  Charity</li>

                                        <li><span className="icon"></span>  BlueSparrow App Launch</li>

                                        <li><span className="icon"><FiCheck /></span>   Contract V2 Launch</li>
                                        <li><span className="icon"><FiCheck /></span>  Contract Audit V2</li>



                                    </ul>
                                    
                            </div>


                            <div className="col-lg-3">
                            <p><h4>Phase 4</h4></p>

                            <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span>   Bonus Competition</li>
                                        <li><span className="icon"><FiCheck /></span> NFT Platform Launch</li>
                                        <li><span className="icon"><FiCheck /></span>NFT Airdrop</li>
                                        <li><span className="icon"><FiCheck /></span>  Website Launch V3</li>


                                        <li><span className="icon"></span>  Listing On Major Exchanges</li>
                                        <li><span className="icon"></span>  Whitepaper V2</li>

                                        <li><span className="icon"></span>  30000+ Holders</li>

                                        <li><span className="icon"></span>   Surprise!</li>



                                    </ul>
                                    
                            </div>
                        </div>
                    </div>
                </div>

                        
                    </div>
                </div>
                {/* End road Elements Area  */}

                <Separator />

                 {/* Start Elements Area  */}
                 <div className="rwt-team-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Press Release"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <ul className="list-icon">
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://www.youtube.com/watch?v=BUcC3Uickzc" target="_blank">New to the Street TV -BlueSparrow Token's interview with Terri-Leigh Bennett, Operational Manager.</a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://www.gurufocus.com/news/1817972/new-to-the-street-tv-announces-five-business-interviews-episode-364-airing-on-the-fox-business-network-tonight-monday-july-11-2022-at-1030-pm-pt" target="_blank">GuruFocus - New to The Street TV Announces Five Business Interviews</a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://bitcourier.co.uk/news/bluesparrow-interview" target="_blank">BitCourier - BlueSparrow Token was Designed to Benefit People </a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://www.binance.com/en/how-to-buy/bluesparrow-token-new" target="_blank">Binance - How to Buy BlueSparrow TokenAugust 26, 2022</a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://hopify.in/2022/07/11/new-to-the-street-tv-announces-five-business-interviews/" target="_blank"> Hopify - New to The Street TV Announces Five Business Interviews</a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://appadvice.com/app/bluebit-ltd/1621496035" target="_blank">Appadvice - Launched on 31st March 2022, BlueBit is a multi-asset, centralized exchange</a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://sourceforge.net/software/cryptocurrency-exchanges/iphone/?q=bluebit" target="_blank">Sourceforge</a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://www.capital-news.in/story/138823/bluesparrow-token-launches-its-exclusive-centralized-exchange-for-all-traders-in-march-2022.html" target="_blank">Capital News - BLUESPARROW TOKEN LAUNCHES ITS EXCLUSIVE CENTRALIZED EXCHANGE </a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://www.iqstock.news/n/street-tv-announces-business-interviews-episode-364-airing-fox-business-network-tonight-4183902/" target="_blank"> IQ Fin - New to The Street TV Announces Five Business Interviews</a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://www.newtothestreet.com/bluesparrow-tokens-interview-with-terri-leigh-bennett-operational-manager" target="_blank">New to the Street - BlueSparrow Token’s interview with Terri-Leigh Bennett, Operational Manager.</a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://twitter.com/utoday_en/status/1560232616871198720?s=21&t=Wv7zhXWP8VGF4Nwko9vVJw" target="_blank">Twitter - DogeCoin listing on BlueBit Exchange</a></li>
<li><span className="icon"><FiArrowDownRight /></span> <a href = "https://cryptosaurus.tech/shiba-inus-bone-token-surges-over-200-after-listing-on-bluebit-io" target="_blank">Cryptosaurus- Shiba Inu’s Bone token surges over 200% after listing on BlueBit.io</a></li>


</ul>
                        </div>
                    </div>
                    {/* End Elements Area  */}


              
                <FooterFour />
            </main>
        </>
    )
}
export default Company;
