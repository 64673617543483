import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";

const Suz = () => {
    return (
        <>


            <SEO title="Suzume-" />
            <Layout>
                <BreadcrumbOne 
                    title="Suzume"
                 
                />
                <div className="main-content">



                    
                    <Separator />


 {/* Start Service Area  */}
 <div className="rwt-brand-area rn-section-gap" >
                        <div className="container">
                            
                        <iframe src="https://www.flipbookpdf.net/web/site/835b249c9c94abfe2593477ab4b47a491ddc887aFBP25139415.pdf.html" height="1000px" width="100%"></iframe>

                        </div>
                    </div>


  
                    {/* End Service Area  */}

                   

                   


                    
                </div>

            </Layout>
            
        </>
    )
}

export default Suz;
