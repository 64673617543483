import React from 'react';
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews"
import Layout from "../common/Layout";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceTwo from "../elements/service/ServiceTwo";
import Separator from "../elements/separator/Separator";
import { FiArrowRight, FiCheck, FiFile, FiArrowDownRight } from "react-icons/fi";


const Service = () => {
    return (
        <>

            <SEO title="Draw Winners-" />
            <Layout>
                <BreadcrumbOne 
                    title="Draw Winners"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Draw Winners"
                />
                <div className="main-content">



                    
                    <Separator />



                    <Separator />

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            
                            <ServiceTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                             />

<p><br/><br/> <b><a href="https://learnnft.bluebit.io/folder/Draw-Wallets.pdf" target="_blank" > <FiFile /> View all Dates</a></b></p>

                        </div>

                    </div>
                    {/* End Service Area  */}


                    

                   


                    
                </div>

            </Layout>
            
        </>
    )
}

export default Service;
