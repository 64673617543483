import React from "react";

export default function App() {
  // A function that can return an array of blobs of various types and we'll put that on the clipboard
  const generateCopyPayload = () => [
    new Blob(
      [
        `<a title="Here's a link to Google" href="https://www.google.com">Here's a link to Google</a>`
      ],
      { type: "text/html" }
    ),
    new Blob(["0x24cCeDEBF841544C9e6a62Af4E8c2fA6e5a46FdE"], { type: "text/plain" })
  ];

  return <CopyToClipboard kind="icon" onCopy={generateCopyPayload} />;
}

const CopyToClipboard = ({
  kind = "icon",
  onCopy,
  onError,
  ...buttonProps
}) => {
  const onClick = async (event) => {
    try {
      const blobs = await Promise.resolve(onCopy(event));
      if (blobs.some((blob) => !(blob instanceof Blob))) {
        throw new Error("Welp! Sorry current we only support Blobs!");
      }

      const data = new ClipboardItem(
        blobs.reduce((acc, blob) => {
          acc[blob.type] = blob;
          return acc;
        }, {})
      );

      await navigator.clipboard.write([data]);
    } catch (error) {
      // Show an error, or fallback to execCommand APIs!!
      console.error(error);
      if (onError) {
        onError(error);
      }
    }
  };

  const props = { onClick };

  return (
    <button class="btn-default btn-small btn-border" {...buttonProps} {...props}>
      Copy Contract #
    </button>
  );
};
