import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceOne from "./ServiceOne";
import ServiceTwo from "./ServiceTwo";
import ServiceThree from "./ServiceThree";
import ServiceFour from "./ServiceFour";
import ServiceFive from "./ServiceFive";
import Separator from "../separator/Separator";

const Service = () => {
    return (
        <>

            <SEO title="Draw Winners || Blue Sparrow Token" />
            <Layout>
                <BreadcrumbOne 
                    title="Draw Winners"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Draw Winners"
                />
                <div className="main-content">



                    
                    <Separator />



                    <Separator />

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            
                            <ServiceTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}


                    

                   


                    
                </div>

            </Layout>
            
        </>
    )
}

export default Service;
