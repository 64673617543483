import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";

const mag = () => {
    return (
        <>

            <SEO title="Magazine-" />
            <Layout>
                <BreadcrumbOne 
                    title="Magazine"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Magazine"
                />
                
                <div className="main-content">



                    
                   


 {/* Start Service Area november */}
 <div className="rwt-brand-area rn-section-gap" >
                        <div className="container">

                        <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Nest Magazine November Edition"
                                        title = ""
                                        description = ""
                                    />
                            
                        <iframe src="https://www.flipbookpdf.net/web/site/2d7a634864aec3c65ac7f6cce10f5f71d03883d2FBP25139415.pdf.html" height="1000px" width="100%"></iframe>

                        </div>
                    </div>
                    {/* End Service Area novemeber */}



                     {/* Start Service Area octboer */}
 <div className="rwt-brand-area rn-section-gap" >
                        <div className="container">

                        <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Nest Magazine October Edition"
                                        title = ""
                                        description = ""
                                    />
                            
                        <iframe src="https://www.flipbookpdf.net/web/site/727a48d1bf85428b7a2059f5d9f2a005f0d628ceFBP25139415.pdf.html" height="1000px" width="100%"></iframe>

                        </div>
                    </div>
                    {/* End Service Area october */}



                    {/* Start Service Area september */}
 <div className="rwt-brand-area rn-section-gap" >
                        <div className="container">

                        <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Nest Magazine September Edition"
                                        title = ""
                                        description = ""
                                    />
                            
                        <iframe src="https://www.flipbookpdf.net/web/site/6b96d4b2c9880ce87e95d51705d05c500ff0ab60FBP25139415.pdf.html" height="1000px" width="100%"></iframe>

                        </div>
                    </div>
                    {/* End Service Area september */}

                    {/* Start Service Area august */}
 <div className="rwt-brand-area rn-section-gap" >
                        <div className="container">

                        <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Nest Magazine August Edition"
                                        title = ""
                                        description = ""
                                    />
                            
                        <iframe src="https://www.flipbookpdf.net/web/site/4bb6f0159c6101b3085e12e8127ed7cbfc3719adFBP25139415.pdf.html" height="1000px" width="100%"></iframe>

                        </div>
                    </div>
                    {/* End Service Area august */}

                   

                   


                    
                </div>

            </Layout>
            
        </>
    )
}

export default mag;
