import React from 'react';

const BrandList = [
    {
        image: './images/brand/gecko1.png',
        link1: 'https://www.coingecko.com/en/coins/bluesparrow'
    },
    {
        image: './images/brand/eth1.png',
        link1: 'https://etherscan.io/token/0x24ccedebf841544c9e6a62af4e8c2fa6e5a46fde'
    },
    {
        image: './images/brand/coin1.png',
        link1: 'https://coinmarketcap.com/currencies/bluesparrow-token-new/'
    },
    {
        image: './images/brand/kyc1.png',
        link1: 'https://app.airnfts.com/nft/BLUESPARROWTOKENCOM_1639041509146'
    },
    
    {
        image: './images/brand/team2.png',
        link1: 'https://www.team.finance/view-coin/0x24cCeDEBF841544C9e6a62Af4E8c2fA6e5a46FdE?name=BlueSparrowToken&symbol=BlueSparrow'
    },
    {
        image: './images/brand/dex1.png',
        link1: 'https://www.dextools.io/app/en/ether/pair-explorer/0xd2ce784bc025e08de15e3ca23a95a8c47343604e'
    }

    
]

const BrandTwo = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href={`${data.link1}`} target="_blank">  <img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandTwo;
