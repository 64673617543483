import React from 'react';
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews"
import Layout from "../common/Layout";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceTwo from "../elements/service/ServiceTwo";
import Separator from "../elements/separator/Separator";

const Service = () => {
    return (
        <>

            <SEO title="About us-" />
            <Layout>
                <BreadcrumbOne 
                    title="About us"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="About us"
                />
                <div className="main-content">

                <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">

<p> <b>What is BLUESPARROW？</b><br/><br/>

The BlueSparrow token is the official native token to <u><a href="https://bluebit.io" target="_blank" >BlueBit.io</a></u> which offers full Bluebit Exchange with Web app, iOS app and its very own NFT Marketplace. 
<br/><br/><br/>

<b>Why BLUESPARROW？</b><br/>

When BlueBit users buy BlueSparrow the result is a continuous burn of the supply of (currently) 45 Million, in addition to regular burn events derived from the platform’s fees at BlueBit CEX and BlueBit NFT. 
<br/>Furthermore, in the first year of operation BlueSparrow had a tax delivered back to holders by a draw system totaling over $700,000 and built 2 schools in Africa and the Middle East including a water tower to a village in Kenya which continues to help over 5000 people.

</p>
                   

</div></div></div></div>



                    

                   


                    
                </div>

            </Layout>
            
        </>
    )
}

export default Service;
