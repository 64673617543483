import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
      

<div className="button-group">
                                        <a className="btn-default btn-small round btn-icon" target="_blank" href="https://bluebit.io/en_US/">BlueBit Exchange<i className="icon"></i></a>
                                        <a className="btn-default btn-small btn-border-blue round btn-icon " target="_blank" href="https://bluevinci.io/">BlueVinci NFT Marketplace<i className="icon"></i></a>

                                    </div> 


       
            

        </ul>

        
    )
}
export default Nav;
