import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {

        date: '10-28-2022',
        
        w1: '0x426266902bc5db82654428d29cf0b4dce7550f9b',
        
        w2: '0xdf89cd9491bb64eb5da3fb53e5cd7eb673576bb0',
        
        w3: '0x69b81431ab8799fd425f810e221d0fb6876c3e00',
        
        w4: '0xe4eb6e3fc14fdf0e617579946d74c95c4f172159',
        
        w5: '0xb648c16866d8b941685e88cfc59d41f18387b86c',
        
        w6: '0xb1c49d7b820c21b2896e408a5dd9df2bfcf40022',
        
        w7: '0x76d61de417d40462a9513e70ad80366873c8acbd'
        
        },
        
        {
        
        
        date: '10-27-2022',
        w1: '0x7a34f5fd81cc331e0068958f954165efb9348bc2',
        
        w2: '0x5913deb6efa4322dc764a7506195244eee2fa228',
        
        w3: '0x4649267cc0039bd6f20755ae4cb72cd761a239f4',
        
        w4: '0x2ce500d05a09e5c13a8a655f1a04b3c8dcca35d6',
        
        w5: '0x19dc51f62d46bff708f1efef98a20d00aa70adab',
        
        w6: '0x86a8cc97a3e5abb6acb6a7fcde627859fcc0fbe9',
        
        w7: '0x3d316b25363a04e870fbfba989416767274a6f7d'
        
        },
        
        {
        
        
        date: '10-26-2022',
        w1: '0x315e5842099544cc2961e0988c628b10b3270288',
        
        w2: '0x721e3b93d1d0bb95feeaf0b9c6a45a102b4641c5',
        
        w3: '0xfbdeb87969f5610d006d1a4ed79308a5778e77e5',
        
        w4: '0x68f8bdefcc30cae71b13624514fae27a797c8afd',
        
        w5: '0x4cfaf9dcfbc39e2e855b8f939aedee3deb37b054',
        
        w6: '0xd27294f8606b2f0c86acb9e18f75289693aabbbd',
        
        w7: '0xb051732b57c1250b8bf301d83764c828d7ee7eb6'
        
        },
        
        {
        
        
        date: '10-25-2022',
        w1: '0x03465d4aba39dd0ce6f6f5f5b58aa52d798075a1',
        
        w2: '0x9ffd86d4d13d0e6a68a25e5462752d9526420bad',
        
        w3: '0x562e7a588827d15552694b287a50ad8cd3e61a34',
        
        w4: '0x92c851d2512cdab0fb652a850ff20ce819368565',
        
        w5: '0x270cc4c5ddf6e221fba089d71f6ae325dda9e33d',
        
        w6: '0xe1d581f8a80a8f9971af90fb84a5d907b7017add',
        
        w7: '0x69ba2e9a0652d1afd4c5c02bc6197dd31888b1d4'
        
        },
        
        {
        
        
        date: '10-24-2022',
        w1: '0xf10bfb9392615ade38151a88606cf69d47d03739',
        
        w2: '0x89c349f2db5fef5a5dc7e19e20a7af370aba4f87',
        
        w3: '0xdce8a844e796128beb9df95f3d1e50933a0c6fb3',
        
        w4: '0x508ddbb6f633d7e5142e1f2bae659e6b865bb431',
        
        w5: '0xe73fa9d5deb9ace85810a0747c6d0c5f9c20a119',
        
        w6: '0x7c2ef9eed54c0a8a64782745a8e2ce9d6e6ce54f',
        
        w7: '0x7ca4c6dd8e8754e9a767d21e0542549da58dd374'
        
        },
        
        {
        
        date: '10-23-2022',
        
        w1: '0x02f8ba327a3acd24703d34971ea2ff7f5159bba3',
        
        w2: '0xaf161a5847d6c825e1949b75d1afbc52920dcb54',
        
        w3: '0x6fc54f181bc1dfcb1b4e066104aef97956ee48be',
        
        w4: '0xc5686e0c34b5f37dc2e489aa8add0d57a6915cb7',
        
        w5: '0x636d2d73a7a9f7f6ae511a6f007b6e797f3d6234',
        
        w6: '0x721e3b93d1d0bb95feeaf0b9c6a45a102b4641c5',
        
        w7: '0xbdc5b8e027de6d93b5fa55de8fb829bc2f418dcb',
        
        },
        
        {
        
        date: '10-22-2022',
        w1: '0xe2a2cc34909f2e34e1acc94c81953d24f58b4875',
        
        w2: '0xdcc29adcf1cccf74aa3ee2a537deaa7db4e25612',
        
        w3: '0x88c2b83e15c4f5e451902c4d01957221690eaf93',
        
        w4: '0xa120125ff5e6aeeebab6ce4849d43302f87d4786',
        
        w5: '0x9693d26d24e08ac77764cf95fabdd1ee6174bed1',
        
        w6: '0xbe27e2e37687ba068777709f73d5fe21a5b77b0e',
        
        w7: '0xf2a371035c99e49caf9cf1679c98811c77963c8b'
        
        },
        
        {
        
        date: '10--2022',
        w1: '0xcbaeb881cb4523554633d39dc0f274dcdb888ad2',
        
        w2: '0xab296b92c2b6b869bd7c99407ced9a481063a2c9',
        
        w3: '0x4462a86daf5e33f565f55b80192e22bb3b92ed27',
        
        w4: '0x97599270a7472fba4480dd73baa8ef64b5e9109b',
        
        w5: '0xbd7fab9cbed7ebc298e2444d1b6f51adf953de58',
        
        w6: '0x8fe7b75071c997e1440632a6e99bf6eac374c3a3',
        
        w7: '0x86701edc4d345ecbacbdacf4eb4649a93e27e4ad'
        
        },
        
        {
        
        date: '10-20-2022',
        w1: '0x52be54095cfa27961840e649041504e48f4d2c09',
        
        w2: '0xe5488f69473ebd323deaa3859d838e39b4529196',
        
        w3: '0x0a4c59cd3fad31a7e58824d32054c43a6f81d724',
        
        w4: '0x18369750714cdb26905ed79cc033701d041e9f22',
        
        w5: '0x22ac8c58f38191e8deeb38da6a138f57eced7f28',
        
        w6: '0x749daf6250f70f6c835c9565a1de1c51b5b9f336',
        
        w7: '0xddd0b973c334fa7383d30ad58f82569d8a165b41',
        
        },
        
        {
        
        date: '10-19-2022',
        w1: '0xb3826e9b2e065ccea77b87455624a5c5913f62d0',
        
        w2: '0xc88a399222df9aa14c49d7ebbada43a997dad335',
        
        w3: '0x1dd1cca56ffff7c3134188184e8e95e52b5bfaff',
        
        w4: '0x34bb8d2884d939971edaa6401126107c06dd2f6b',
        
        w5: '0xc9d82482064c25c54679bca2b52171bc3806b6ee',
        
        w6: '0xb487de0e14cf4cfbcb73b2fca01bfbbcfef6de0e',
        
        w7: '0x6863f0f9ad702f9080c73a482f0fc0b6798ff1d3'
        
        },
        {
        
        
        
        date: '10-18-2022',
        w1: '0x3d2b19c2bab8683b4353d15fdcb8e817a60f976f',
        
        w2: '0x028dbd4ba9ad6e313b74cef6c304c650b0f220d2',
        
        w3: '0xefb46b22da866880eaa45949856ad7d526a202d6',
        
        w4: '0x2366b8cd52d3a139d9e12769971e53501d3c5333',
        
        w5: '0x9633ea3eb3d7e7dbd2e8c5d80cbe8dde24f0672b',
        
        w6: '0xf48b7eeb4f2e71a4c1ae2818853e1f2f885d1e86',
        
        w7: '0x4a58158202e98aa6a2b0399081d7f4062d7e8946'
        
        },
        
        {
        
        date: '10-17-2022',
        w1: '0x77002f670fe0ec9aafacf363406aff211b28fbd4',
        
        w2: '0x15843d1857f9d42cfa67bc5dc9de04bf82050e5b',
        
        w3: '0x49bf355c5349891617c210446a4d1742e6a5d4e4',
        
        w4: '0x0cce23640fbacf5463951199dfbc0ae70c02e75d',
        
        w5: '0x034cd112c996c609a71ba7c455c8120e44a2569f',
        
        w6: '0xbfd12fdffa59c7cefdf41d7f2ecf2da307d21b6c',
        
        w7: '0x4a58158202e98aa6a2b0399081d7f4062d7e8946'
        
        },
        
        {
        
        date: '10-16-2022',
        w1: '0x68273be8c56f5b8c406cf8d88a371d4bb30a71f1',
        
        w2: '0xafc697a680ec5156b190bb1b58bcb9fb7d8c1a17',
        
        w3: '0xc17ff57ba00b445c3d7cfad81bf2b7c7f3a37d5b',
        
        w4: '0x2380a555f9621ed6f1ba5d21eb41463899e92f11',
        
        w5: '0xb18b4d6b9b2066e2ee86b77007dfcb4bda5dd5c7',
        
        w6: '0xa3d716b1bd5c6455883a30e01bafe26edf56be30',
        
        w7: '0xaa3f2383d20d0c3f626ed3b3ee9a182be4bd4120'
        
        },
        
        {
        
        date: '10-15-2022',
        w1: '0x1367d83ee3149b7978eac64b5b2efafaf131592f',
        
        w2: '0x59cb81ab4063f193f7baba0f775f467892e8499e',
        
        w3: '0xcd215545435231b5c84a2934ccf12223ecaa30da',
        
        w4: '0x01d595f820667bdb2cd17cdadd60aafcf905efe0',
        
        w5: '0x977fbbdd3bb33431cd0ab2a57c7061d80cc29aed',
        
        w6: '0xe12d06d0fa012fb7d6c88e080ae6623a44291fa4',
        
        w7: '0x369da5fc7ace77bf567f9f330f379f1944b6fce8'
        
        },
        
        {
        
        date: '10-14-2022',
        w1: '0x718f668bfbb3ff423a18925b0984dcf9f22b0515',
        
        w2: '0x78d9c2955974711c20a045c1b339bf1a778b877a',
        
        w3: '0x10c671872b63764b1d7f21ae8659f9e5e3e97f11',
        
        w4: '0xe7258cc9750ac7f728dcfacd373a27b521b5c729',
        
        w5: '0xbf22b2190c7b148aca818eef4879c43f82fbc10a',
        
        w6: '0x538407de81c151ec149e06c81e026eb65ce8b1ae',
        
        w7: '0x0e1e85079a91441603d383eb24b41ba5464a4b97'
        
        },
        
        {
        
        date: '10-13-2022',
        w1: '0x964703fd4de491a2ccba388992429a17003da417',
        
        w2: '0x8ad57d4503743f65d7d315ded7a20a8a166c6463',
        
        w3: '0x6cb8ba3cd6e525084e47c59760a1324e3ed18980',
        
        w4: '0xc87b4b04929e142bfe2dd50471f902044027ed4d',
        
        w5: '0xf084e63d3f13abec29cc08c849143475999f8d4a',
        
        w6: '0x300c7435f43e1e93bedc268df2ebf99d81db7de7',
        
        w7: '0x4ec8c70287dce1e3294a7322911b428b5da5a45a'
        
        },
        
        {
        
        date: '10-12-2022',
        w1: '0xf0b2b6a012c56c8906c60af237da7b37d29ec588',
        
        w2: '0x49811a991b8ea0dfbe06a03e9b193c7882a26ef1',
        
        w3: '0xd380f2d84155703f890c8b6647dcd5333a4ca53a',
        
        w4: '0xe9c52ac4f024ff34e13f72301a0965e9cb828f67',
        
        w5: '0x11ae9b8168d4b79895dbd91182ea69c0a3560249',
        
        w6: '0x6a8e832b64ed251476f8343e9a7f294f2b5732a3',
        
        w7: '0x0ca83b8334edef2165be6f3beb0fb600b05bae3e'
        
        },
        
        {
        
        date: '10-11-2022',
        w1: '0xa7be9f250f03d044c1878a1a0f81f227b784436c',
        
        w2: '0x38271c7b42212bd2ee2d9a4f737051174dc8d590',
        
        w3: '0x5f5e4e1762d9fe14fd2ed8848b45b1c21bf0a68e',
        
        w4: '0xcf44d3bd5d4d77884a86aeeb38869bd0486a64e7',
        
        w5: '0xecfc865bbca005873083bb9641c521186f864c41',
        
        w6: '0xb24ae79063872591d8c1bc4da6748dede8c26476',
        
        w7: '0xf08b38e58a4f0d64176ffadadc3621cf40feb757'
        
        },
        
        {
        
        date: '10-10-2022',
        w1: '0x4e83ca7e4ff515672b007bcd66fa0123485f0077',
        
        w2: '0x7d303bf2bc20816bf1335f40cb670ac499e80377',
        
        w3: '0x75ce2d39afb9e88957b59d3bd6763928c35f184c',
        
        w4: '0xfa4a64652af9ca5c6ad623dcda388777edfa27f1',
        
        w5: '0xc38cf836cb0d36de3cf00bb08b4bf15638e93ba5',
        
        w6: '0x08b78b06153ee889d502dc0b313bd9afcfc816c4',
        
        w7: '0x10d5adf0e7f8f8e01eb194983321cd1769198fca'
        
        },
        
        {
        
        date: '10-09-2022',
        
        w1: '0xc4fea4d751103fd1b3a53e3dad7a92b6ef6e899e',
        
        w2: '0x868564093fbe9960eeadc87437aabdf00ec106c4',
        
        w3: '0x8982d5c05662c17fbdcfb80849c5270f32d0a678',
        
        w4: '0x4de220f9d4491a1e9ea3bc6ccc1e880c49dc55ac',
        
        w5: '0x71b7c01f9fedb61fbe767ce62df8342ff0555abb',
        
        w6: '0x1c8673761dfe1b534fe4e27ecb75c5d4fc9ea922',
        
        w7: '0x5d79dad866e52ca8cae9b4d7b05784f4d377f4c2'
        
        },
        
        {
        
        date: '10-08-2022',
        
        w1: '0x74a726829099c923da0740eac33508785ce3dbcd',
        
        w2: '0x208cacd469ea0f4fcbceb6961631e56d4b0f3d29',
        
        w3: '0x133035b6392c9e2b3a2c7a3055cf6119d8862ee9',
        
        w4: '0x99706e51cf73f49b27e511c321737dc9a7577eec',
        
        w5: '0xe66d128a6e49d286f3b32ddb9709db0af97cff5f',
        
        w6: '0xccc16f788eb53cb55712638e6b619230a36f4926',
        
        w7: '0x74bf0c9d6b8ca0215843a0f9f4668b1f8ed01c8e'
        
        },
        
        {
        
        date: '10-07-2022',
        w1: '0xcb62e0c335971495c9173f311ba455cdbbb68460',
        
        w2: '0xadb6ea88c97bcbc2ab0da579a00540c7f8be28e9',
        
        w3: '0x4730b9599c62b8b4854eba2d998628175ebdd169',
        
        w4: '0xfffe0bea3dfff8cfe0250f672d6f09055155457a',
        
        w5: '0x196364b179e24c3e82af4992cbeb4cdc99676abc',
        
        w6: '0x146da268c5dcf3e6cd6eaff06e6506163122ceda',
        
        w7: '0x2f29f3586dcd72c0dd0b8b040770333560908e47'
        
        },
        
        {
        
        date: '10-06-2022',
        w1: '0x9f51b514f3b856f823aa8ec0a99b0085d43145c3',
        
        w2: '0xb2a421eed8c14116150ca32278218f6301d8f285',
        
        w3: '0x7260b59d101066405a63599af72a87f0533d52d7',
        
        w4: '0x3088178fa6811d9ac44e17502e005e2fd419241b',
        
        w5: '0xac6e046f4937f0f2e39447b66144ad5bd36d1dc6',
        
        w6: '0x3fe3593e5dd5367066ea99f025e9ad8f164e77f6',
        
        w7: '0x1a7921629a9ec8bc07322debc7d5656b11015fa1'
        
        },
        
        {
        
        date: '10-05-2022',
        w1: '0x98a812469d9912fde32398b4ddb20251c643b6a6',
        
        w2: '0xf12bc958d417a5eaa20e89cea9c060d933189d85',
        
        w3: '0x6d676c21db501ea60a34b1a255ee92a5c163f685',
        
        w4: '0x94108c25be6500b6c04f3fe1a51c2ec5fc7c6c7c',
        
        w5: '0xddd9aa02c8c3bf67a417c326a05074bce84e6940',
        
        w6: '0x580df86bee04b5c2599502841b9971d2d4fb9eef',
        
        w7: '0x5ec64d338018aedf2b1c15c9582f22ab27ed065e'
        
        },
        
        {
        
        date: '10-04-2022',
        w1: '0x90a6f581abfaf28e7dce4dcfa374ae52aeeff576',
        
        w2: '0x553ce8b34b25e3bafa00d43059b091a5575ff3d1',
        
        w3: '0x3a436194a3105dd92d69da72a7631508badbbd56',
        
        w4: '0xab704aaa447b12c8cc24c9da59b491ea991e1c7d',
        
        w5: '0xfeaffd6e59478f1d068e6ef160afe443c5433b08',
        
        w6: '0x196364b179e24c3e82af4992cbeb4cdc99676abc',
        
        w7: '0x7339fce89e9ffc77f7b2eaef9c5f5bf9369437cd'
        
        },
        
        {
        
        date: '10-03-2022',
        w1: '0x675a4cba02409023d31d468e835e356b2ee1f3ce',
        
        w2: '0xddb8f436c5157f674855454ca8d0f1d43a11aee3',
        
        w3: '0xba1443aee2307e27816dfee8a32329679b801382',
        
        w4: '0x4c7f0f1eee8d81a40ae0a4649c8d54cfda3098a0',
        
        w5: '0xa7be9f250f03d044c1878a1a0f81f227b784436c',
        
        w6: '0xc9fc50e798f1e76410d1bda5f1607da504a33725',
        
        w7: '0x8d620a6593ae64fc6b56777270c8adbb9ec76647'
        
        },
        
        {
        
        date: '10-02-2022',
        w1: '0x68536c11a33f51c14d7abcf0ad11d8589684ac37',
        
        w2: '0x9947ee653c18408ea88223c2ce054557f46faae1',
        
        w3: '0xf8e225db1667f0346fcb704143c8c7af441f5b11',
        
        w4: '0x80786cf69222671d49b68301d437d991dfd1c20b',
        
        w5: '0xeeee23e473ea742250b27e9d944d6fee3f2f34c1',
        
        w6: '0x4adef629c37189895d17e41e639911ac6591afc4',
        
        w7: '0x321b00829d7b4005294f6f9a9a69046f593cf5fa'

        },

        {
        
        date: '10-01-2022',
        
        w1: '0x39b176b5430a802ba4e469d1010d4999eb41f8f1',
        
        w2: '0xa20d36882b1e84a75c38915ec6a184c442e98bf4',
        
        w3: '0x6becb182d54db93f648f41d30059428f736a9b00',
        
        w4: '0xc2137ad41dab02bf334d5a42fffc94241343e212',
        
        w5: '0xb907aea0afab298fa8d1373709c5a098395d0b8d',
        
        w6: '0x2680532822814abc11ea052b7ddd68c19c6fa106',
        w7: '0x2927986fa2c38b7d3b7a8ef386d05d1f73c49d04'
        
        },
        
        {
        
        date: '09-30-2022',
        
        w1: '0x14785cb4e5cdc102e86343f1d7bf867df2017078',
        
        w2: '0xaf0c24f396acd812d8f2088cfcfe4e4cc0e8dba6',
        
        w3: '0x37f08b5790d603d6846594e38b9827693e65b1bc',
        
        w4: '0x5efbcd6e740e829dc3c85631a7429215bbdd7ed0',
        
        w5: '0xb0731d0c54fef44013fd3ccd22f2a27c4517bdb4',
        
        w6: '0xd7d0632d3e1ed3db7e97ab922005ebfc7bf5ef1f',
        
        w7: '0xaf00faaaf938d856394e5f1fb3fa2d5c2493d5fd'
        
        },
        
        {
        
        date: '09-29-2022',
        w1: '0x33903042bf3e523659834ab58d055f242cd4e4ff',
        
        w2: '0x5dc639e7fda6d41c6839cba1db0e86898bb126fc',
        
        w3: '0x1e50cb9c9d9e6f8c4a32944315b504186e38e8de',
        
        w4: '0x11ca3fba6d3016cb5743128879f639444ff598f5',
        
        w5: '0x5dac99662a6e6b27e9be94a2652929ededc54df0',
        
        w6: '0x6da860a460482315f9c0d0649537a96482572265',
        
        w7: '0x8febc125f567a9781af46bcb346404eeb189cedf'
        
        },
        
        {
        
        date: '09-28-2022',
        
        w1: '0xc98f222ebfb6c1c01f3706b66966aa79b599939a',
        
        w2: '0xd933ab3a66f035cbec542ea68994bd9b2766cf83',
        
        w3: '0xa2022be985db84bcbe2e11f307c492d60c154340',
        
        w4: '0x4b3495e204be7f85021310a8e1871dbdd916d654',
        
        w5: '0x58d32df3641d82ee9b61c638d62fffaed73bfd4b',
        
        w6: '0xc4e3733b7aff6d200cc5614ab3a5681aee061d70',
        
        w7: '0x599d264a6ac00aa841e5169f8f717dca7612023b'
        
        },
        
        {
        
        date: '09-27-2022',
        
        w1: '0xd06206f603477c4a691a60931ebe2795ddae6f5e',
        
        w2: '0xe0b7334d4ab1a8138a6c2b69d1f024dc33f12790',
        
        w3: '0xe87cbe4565fb9370838f5aaf9d59daa38390540a',
        
        w4: '0x64b879ec09639702a267262ac1c1a16a398eba56',
        
        w5: '0x73f4d219f88e93f20b064582a717e617b3a3343a',
        
        w6: '0xf988abc1a066817472bdedd3cc3a9b90d167497b',
        
        w7: '0x6a1037b2919504f3b994a0397e167753f1ffccc8'
        
        },
        
        {
        
        date: '09-26-2022',
        
        w1: '0x85dbf52289412631621ffb5aa716089b4a3c23c6',
        
        w2: '0xbcad2296306f071af57e8245684139599a7723ed',
        
        w3: '0x8b226a8e7e2dd35d9b8e913211f0aedfeff9b608',
        
        w4: '0x76c95de4a132b5fcc4ab1c0b436bf868ad1885b6',
        
        w5: '0x90a38959e8ea573f1b2aae633ee5a73b09568dfe',
        
        w6: '0x52832331698e0d8f0497a057089976bde337371b',
        
        w7: '0x4e590a2da5136af3ec5c415677b98a8f88aba263'
        
        },
        
        {
        
        date: '09-25-2022',
        
        w1: '0xd696b6b2aa830d6d9622d2c14acd8a8bd54e06e6',
        w2: '0x707e2c3c56339b286b45089597da2eff5cf917d7',
        w3: '0x9da085540050817325a64107c4a30dbf8871560e',
        w4: '0x5b7536224ef92abf3a6d385309a81908a3897a40',
        w5: '0xb26fe05c3983f84bcee0b2f304765be22312b2a0',
        w6: '0xef5c41aa87c88e3ba771e8fee2b6c903e7abc021',
        w7: '0x1e0638ec6494a00ddac9a7be5b34bb71c499ba0a'
        }
]
const ServiceTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                
                                <div className="content">
                                    <h4 className="title mb--20">Date: <Link to="#service" dangerouslySetInnerHTML={{__html: val.date}}></Link></h4>
                                    <h4 className="title mb--20">Winners List</h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.w1}}></p>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.w2}}></p>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.w3}}></p>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.w4}}></p>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.w5}}></p>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.w6}}></p>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.w7}}></p>

                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;