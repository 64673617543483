import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'pic1',
        name: 'Suzume',
        designation: 'Founder',
        
        socialNetwork: [
         
            {
                icon: <FiTwitter />,
                url: 'https://twitter.com/SuzumeBLS'
            },
         
        ]
       
    },
    {
        image: 'pic2',
        name: 'Baby Sparrow',
        designation: 'CFO',
        
        socialNetwork: [
       
            {
                icon: <FiTwitter />,
                url: 'https://bluesparrowtoken.com/#'
            },
         
        ]
       
    },
    {
        image: 'pic3',
        name: 'Kafka',
        designation: 'DEV',
        
        socialNetwork: [
         
            {
                icon: <FiTwitter />,
                url: 'https://bluesparrowtoken.com/#'
            },
            {
                icon: <FiLinkedin />,
                url: '#'
            },
        ]
      
    },
    {
        image: 'pic4',
        name: 'Terri-Leigh Bennett',
        designation: 'Operations Manager',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: 'https://twitter.com/TerriBlueBit'
            }
        ]
        
    },
    {
        image: 'pic5',
        name: 'A4',
        designation: 'IT Manager',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: 'https://twitter.com/Afourly4'
            }
        ]
        
    },
    {
        image: 'pic6',
        name: 'Shannon Bray',
        designation: 'CHARITY AMBASSADOR',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: 'https://twitter.com/shannonbraync'
            }
        ]
        
    },
    {
        image: 'pic7',
        name: 'Winson Alexander',
        designation: 'HEAD OF MARKETING',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: 'hhttps://twitter.com/Crypto4Winz'
            }
        ]
        
    },
    {
        image: 'pic8',
        name: 'Prashant',
        designation: 'Software',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: '#'
            }
        ]
        
    },
    {
        image: 'pic9',
        name: 'Blue Nebula',
        designation: 'RISK MANAGEMENT',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: 'https://bluesparrowtoken.com/'
            }
        ]
        
    },
    {
        image: 'pic10',
        name: 'Izzo Slim',
        designation: 'CHARITY LEADER',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: 'https://www.facebook.com/izzo.slim'
            }
        ]
        
    },
    {
        image: 'pic11',
        name: 'Marta Macharia',
        designation: 'CHARITY LEADER',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: 'https://twitter.com/mrsmmacharia'
            }
        ]
        
    },
    {
        image: 'pic12',
        name: 'Nobel',
        designation: 'LISTING MANAGER',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: 'https://bluesparrowtoken.com/#'
            }
        ]
        
    },
    {
        image: 'pic13',
        name: 'Thomas Regan',
        designation: 'DISCORD ADMIN',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: 'https://twitter.com/tom_bluebit'
            }
        ]
        
    },
    {
        image: 'pic14',
        name: 'Maxim Azad',
        designation: 'MODERATOR',
       
        socialNetwork: [
            
        
            {
                icon: <FiTwitter />,
                url: 'https://twitter.com/MaximBlueBit'
            }
        ]
        
    },
]


const TeamOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h4 className="">{data.name}</h4>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    
                                    <p className="description">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}` } target="_blank">{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TeamOne;
