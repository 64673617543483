import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight, FiCheck, FiPieChart, FiArrowDownRight } from "react-icons/fi";



const SlpitOne = () => {
    return (
            <div className="rn-splite-style">
                <br/>  <br/>
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail image-left-content">
                                    <img src="./images/split/bs2.jpg" alt="split Images" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="">About BlueSparrow</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">BlueSparrow's utility opens its holders up to the entire financial system, one that serves up the digital world of Crypto and NFTs. Their centralized exchange and marketplace are both powered by BlueSparrow tokens.<br/>
                                    
                                    <br/>The major benefit of being a BlueSparrow holder is the mutual support both these powerful, robust platforms of the centralized exchange and the NFT marketplace provide each other.
                                     <br/><b><a href="/about">Read More</a></b><br/><br/>

</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="">Whitepaper</h4>
                                    </ScrollAnimation>
                                    <ul className="list-icon">
                                        <li  ><a href="https://github.com/BlueSparrowToken/whitepapers/blob/main/BlueSparrow-Whitepaper-English.pdf" target="_blank"> <FiArrowDownRight /> English</a></li>
                                        <li  ><a href="https://github.com/BlueSparrowToken/whitepapers/blob/main/BlueSparrow-Whitepaper-Chinese.pdf" target="_blank"> <FiArrowDownRight /> Chinese</a></li>
                                        <li  ><a href="https://github.com/BlueSparrowToken/whitepapers/blob/main/BlueSparrow-Whitepaper-Arabic.pdf" target="_blank"> <FiArrowDownRight /> Arabic</a></li>
                                        <li  ><a href="https://github.com/BlueSparrowToken/whitepapers/blob/main/BlueSparrow-Whitepaper-Japanese.pdf" target="_blank"> <FiArrowDownRight /> Japanese</a></li>
                                        <li  ><a href="https://github.com/BlueSparrowToken/whitepapers/blob/main/BlueSparrow-Whitepaper-Spanish.pdf" target="_blank"> <FiArrowDownRight /> Spanish</a></li>
                                        <li  ><a href="https://github.com/BlueSparrowToken/whitepapers/blob/main/BlueSparrow-Whitepaper-Hindi.pdf" target="_blank"> <FiArrowDownRight /> Hindi</a></li>
                                    </ul>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SlpitOne
