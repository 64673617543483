import React from 'react';
import {Link} from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import NewsletterOne from "./NewsletterOne";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiGithub, FiSend } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Separator from "../../elements/separator/Separator";



const footerIntem =  footerOne[0];
const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemThree =  footerOne[3];
const footerIntemFour =  footerOne[4];
const footerIntemFive =  footerOne[5];

const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);

const FooterFour = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default no-border">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">


                                 {/* Start Single Widget  */}
                                 <div className="col-lg-8 col-md-6 col-sm-6 col-12">

                                 <Logo 
                                image={`${process.env.PUBLIC_URL}/images/logo/bluesparrow-logo.png`}
                                image2={`${process.env.PUBLIC_URL}/images/logo/bluesparrow-logo.png`}
                                />
                                <p></p>
                                <div className="rn-footer-widget">
                                    <div className="inner">
                                        <ul className="social-icon social-default justify-content-start">
                                            <li><a href="http://twitter.com/BlueSparrowETH" target="_blank"><FiTwitter /></a></li>
                                            <li><a href="https://t.me/BlueSparrowOfficial" target="_blank"><FiSend /></a></li>
                                            <li><a href="https://www.instagram.com/bluesparroweth" target="_blank"><FiInstagram /></a></li>
                                            <li><a href="https://github.com/BlueSparrowToken" target="_blank"><FiGithub /></a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}


                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-8 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemOne.title}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {indexOneLink.map((data, index) => (
                                                <li key={index}><a  href={`${data.url}` } target="_blank" >{data.text}  </a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="widget-menu-top">
                                        <h4 className="title">{footerIntemTwo.title}</h4>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                {indexTwoLink.map((data, index) => (
                                                    <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                           
                            

                       
                        </div>
                    </div>
                </div>
                <Separator />

                <div className="copyright-area copyright-style-one no-border">
                    <div className="container">
                        <div className="row align-items-center">
                            
                            
                                <div className="copyright-right text-center ">
                                    <p className="copyright-text">© BlueSparrow {new Date().getFullYear()}</p>
                                </div>
                            
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterFour;
