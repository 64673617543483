import React from 'react';
import { Link } from 'react-router-dom';

const BrandList = [
    {
        image: './images/brand/br1.png',
        link1: "https://trade.bluebit.io/?symbol=blsusdt"
    },
    {
        image: './images/brand/br2.png',
        link1: "https://www.lbank.info/exchange/bluesparrow/usdt/#usd"

    },
    {
        image: './images/brand/br3.png',
        link1: "https://www.hotbit.io/exchange?symbol=BSPARROWV2_USDT"

    },
    {
        image: './images/brand/br4.png',
        link1: "https://dex.saita.pro/"

    },
    {
        image: './images/brand/br5.png',
        link1: "https://www.xt.com/tradePro/bluesparrow_usdt"

    },
    {
        image: './images/brand/br6.png',
        link1: "https://www.bkex.com/trade/BLUESPARROW_USDT"

    },
    {
        image: './images/brand/br8.png',
        link1: "https://www.bitmart.com/trade/en?symbol=BLUESPARROW_USDT&layout=basic&theme=dark"

    },
    
]

const BrandOne = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href={`${data.link1}`} target="_blank"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandOne;
