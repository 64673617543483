import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import GalleryOne from "../elements/gallery/GalleryOne";
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';




const PopupData = [
    {
        id: "01",
        image: "./images/portfolio/12.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=XuRXqicPmSw',
        ],
    },
    {
        id: "02",
        image: "./images/portfolio/1.jpg",
        popupLink: [
            './images/portfolio/1.jpg',
        ],
            
    },
   
    {
        id: "03",
        image: "./images/portfolio/3.jpg",
        popupLink: [
            './images/portfolio/3.jpg',
        ],
    },
    {
        id: "04",
        image: "./images/portfolio/4.jpg",
        popupLink: [
            './images/portfolio/4.jpg',
        ],
    },
    {
        id: "05",
        image: "./images/portfolio/5.jpg",
        popupLink: [
            './images/portfolio/5.jpg',
        ],
    },
    {
        id: "06",
        image: "./images/portfolio/6.jpg",
        popupLink: [
            './images/portfolio/6.jpg',
        ],
    },
    {
        id: "07",
        image: "./images/portfolio/7.jpg",
        popupLink: [
            './images/portfolio/7.jpg',
        ],
    },
    {
        id: "08",
        image: "./images/portfolio/8.jpg",
        popupLink: [
            './images/portfolio/8.jpg',
        ],
    },
    {
        id: "09",
        image: "./images/portfolio/9.jpg",
        popupLink: [
            './images/portfolio/9.jpg',
        ],
    },
    {
        id: "10",
        image: "./images/portfolio/10.jpg",
        popupLink: [
            './images/portfolio/10.jpg',
        ],
    },
    {
        id: "11",
        image: "./images/portfolio/11.jpg",
        popupLink: [
            './images/portfolio/11.jpg',
        ],
    },
   
]

const PopupData2 = [
    {
        id: "01",
        image: "./images/portfolio/2/1.jpg",
        popupLink: [
            './images/portfolio/2/1.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/2/2.jpg",
        popupLink: [
            './images/portfolio/2/2.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/2/3.jpg",
        popupLink: [
            './images/portfolio/2/3.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/2/4.jpg",
        popupLink: [
            './images/portfolio/2/4.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/2/5.jpg",
        popupLink: [
            './images/portfolio/2/5.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/2/6.jpg",
        popupLink: [
            './images/portfolio/2/6.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/2/7.jpg",
        popupLink: [
            './images/portfolio/2/7.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/2/8.jpg",
        popupLink: [
            './images/portfolio/2/8.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/2/9.jpg",
        popupLink: [
            './images/portfolio/2/9.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/2/10.jpg",
        popupLink: [
            './images/portfolio/2/10.jpg',

        ],
    },
  

]


const PopupData3 = [
    {
        id: "01",
        image: "./images/portfolio/3/1.jpg",
        popupLink: [
            './images/portfolio/3/1.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/3/2.jpg",
        popupLink: [
            './images/portfolio/3/2.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/3/3.jpg",
        popupLink: [
            './images/portfolio/3/3.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/3/4.jpg",
        popupLink: [
            './images/portfolio/3/4.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/3/5.jpg",
        popupLink: [
            './images/portfolio/3/5.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/3/6.jpg",
        popupLink: [
            './images/portfolio/3/6.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/3/7.jpg",
        popupLink: [
            './images/portfolio/3/7.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/3/8.jpg",
        popupLink: [
            './images/portfolio/3/8.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/3/9.jpg",
        popupLink: [
            './images/portfolio/3/9.jpg',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/3/10.jpg",
        popupLink: [
            './images/portfolio/3/10.jpg',

        ],
    },
  

]

const PopupData4 = [
    {
        id: "01",
        image: "./images/portfolio/4/1.jfif",
        popupLink: [
            './images/portfolio/4/1.jfif',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/4/2.jfif",
        popupLink: [
            './images/portfolio/4/2.jfif',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/4/3.jfif",
        popupLink: [
            './images/portfolio/4/3.jfif',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/4/4.jfif",
        popupLink: [
            './images/portfolio/4/4.jfif',

        ],
    },
    {
        id: "01",
        image: "./images/portfolio/4/5.jfif",
        popupLink: [
            './images/portfolio/4/5.jfif',

        ],
    },
    
  
  

]


const Elements = () => {
    return (
        <>
            <SEO title="Charity-" />
           
         
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="5" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/charity1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">     
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <div className="main-content">
                   
                    {/* Start Elements Area  gallery 1*/}
                    <div className="rwt-gallery-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-left"
                                        radiusRounded = ""
                                        subtitle = "Classroom Construction in Masai Mara region in Kenya, Africa."
                                        title = ""
                                        description = ""
                                    />

                                    <p>Our charity journey started with a donation of $10,000 to get the ball rolling. From the foundations to the roof, Izzo and Marta, the charity leaders in Africa, gathered the materials and assembled helpers to build our BlueSparrow school.The children enrolled for the new term in April 2022 and sent us lots of pictures of our infamous Blue Bird. <br/>
 
 <br/>This big BlueSparrow can be found painted on the back of the classroom, watching over the children. Words cannot describe how happy everyone is to see this through to fruition.</p>
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {PopupData.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area 1  */}


                     {/* Start Elements Area  gallery 2 */}
                     <div className="rwt-gallery-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-left"
                                        radiusRounded = ""
                                        subtitle = "Masai Land Water Tower Project"
                                        title = ""
                                        description = ""
                                    />

                                    <p>With great pride, we can announce that the BlueSparrow water tower is complete and operational! The women and girls have access to fresh, clean water. As the girls are responsible for fetching clean water in the region, we decided to do this so that the girls would have more time to attend the school that we built.

This directly impacts over 5000 people, what an achievement!

<br/><br/>A message from Izzo, our Charity Leader in Africa:

<br/>The uproar of hope has penetrated all communities and it has hit me a notch higher on just how much this means to the people here.

“No political leader or our local leaders have been able to achieve what you all have done” a message from a crowd of women that know just how much this will change their lives forever.</p>
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {PopupData2.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area 2 */}

                     {/* Start Elements Area  gallery 3*/}
                     <div className="rwt-gallery-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-left"
                                        radiusRounded = ""
                                        subtitle = "Middle East School Construction"
                                        title = ""
                                        description = ""
                                    />

                                    <p>Construction is currently underway on our latest charity project. The second school will be built to allow more children to enroll and receive the education they deserve. This school is in Middle East and we donated $45,000. <br/> We will keep you posted on our progress.<br/>

                                        With a total of $75,000 being sent between Africa and Middle East so far.</p>
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {PopupData3.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}

<p><br/><br/>We are excited to announce that the construction of our Middle Eastern school is complete!

After months of hard work and a few setbacks due to climate conditions, we are proud to inform you that the construction of our school in the Middle East has finally come to fruition!<br/><br/>

Without our Founder's generous contribution along with the team of workers and organizers who persevered to accomplish this mission, this journey would not have been possible.<br/> The gas is switched on, electricity flows through the wires and water pumps through the pipes. All that remains is to set up the furniture and welcome the children who will be attending for the first time in December!<br/></p>

{PopupData4.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                    {/* End Elements Area 3 */}



                </div>
                <FooterFour />

        </>
    )
}
export default Elements;